<script>
import iptvService from "@/service/api/iptvService";
import IptvService from "@/service/api/iptvService";

export default {
  name: "PlaylistEditor",
  props: {
    accountId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      channels: null
    }
  },
  computed: {
    groups() {
      if (!this.channels) return []
      return Object.keys(this.channels).map(group => {

        const items = this.channels[group];
        const allEnabled = items.every(i => i.enabled);
        const someEnabled = items.some(i => i.enabled);

        return {
          name: group,
          items: items,
          enabled: allEnabled,
          partialEnabled: !allEnabled && someEnabled
        }
      })
    }
  },
  mounted() {
    this.loadChannels()
  },
  methods: {
    loadChannels() {
      this.loading = true
      iptvService.getChannels(this.accountId)
          .then(res => {
            this.channels = res.data
          })
          .finally(() => this.loading = false)
    },


    toggleAll(group) {
      console.log(group)
      const items = this.channels[group.name];
      const allEnabled = items.every(i => i.enabled);

      const state = !allEnabled
      items.forEach(item => item.enabled = state)
    },


    save() {
      const excluded = Object.keys(this.channels)
          .reduce((channels, group) => channels.concat(this.channels[group]), [])
          .filter(channel => !channel.enabled)
          .map(channel => channel.sid)

      if (!excluded.length) return
      IptvService.editPlaylist(this.accountId, excluded).then(res => {
        this.$swal(this.$t('messages.success'),'','success')
      }).catch(err => {
        this.$swal(this.$t('errors.error'),'','error')
      }).finally(() => {
        this.$emit('done')
      })
    }
  },
}
</script>

<template>
    <div class="d-flex flex-wrap playlist-group justify-center">
      <v-progress-linear color="pink" v-if="loading" indeterminate size="100" />
      <div v-for="group in groups" class="" v-if="!loading">
        <v-card light width="300" rounded>
          <v-card-title>
            <v-checkbox :indeterminate="group.partialEnabled" @click="toggleAll(group)" v-model="group.enabled" />
            {{group.name}} ({{group.items.length}})
          </v-card-title>
          <v-card-text>
            <v-virtual-scroll
                :items="group.items"
                :item-height="50"
                height="350">
              <template #default="{item}">
                <v-list-item dense>
                  <v-list-item-action><v-checkbox v-model="item.enabled" dense/></v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-avatar>
                    <v-img width="60" contain :src="item.logo"></v-img>
                  </v-list-item-avatar>
                </v-list-item>
                <v-divider />
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
      </div>
    </div>
</template>

<style scoped>
  .playlist-group {
    gap: 1em
  }
</style>