export default {
    messages : {
        dont_change : "Please do not change the fields of the payment form or we will not be able to identify the payment.",
        success : "Success",
        register : "Registration completed successfully! We have sent you a code to confirm your registration",
        forgot_success : "The link for password recovery was sent to the specified mailbox",
        copied : "Copied to clipboard",
        payment_error : 'Payment failed',
        payment_success: 'The payment was successful',
        alert : 'Attention! If you have problems watching iptv, update the playlist link',
        recover_success : "Congratulations! Password changed"
    },
    faq : [
        {
            title : 'How to top up your account?',
            text : `There are several payment methods on the <a href="/balance">"Top up your balance"</a> page. You can choose the most convenient way for you.`,
        },
        {
            title : 'How do I create an additional account?',
            text : `One one account is automatically created for <strong style="color:orange">Shara</strong> and one for <strong style="color:orange">IPTV</strong> during registration.</br></br>
                    If you need another account for another person(client, relative ...) then at the top of the page click on the <strong style="color:red">Create one more account</strong> button`
        },
        {
            title : 'How long does the subscription activate?',
            text: `For shara - <strong style="color:red">5 minutes.</strong> For iptv - <strong style="color:red">10 minutes.</strong></br></br>
                    Also, when changing the server, you need to wait <strong style="color:red">5 minutes.</strong>`,
        },
        {
            title: 'Where can I find the settings for the receiver? Where can I find settings for NewCamd/MGCamd/Cccam/Oscam// Wicardd ... Emulators?',
            text: `Click on the <strong style="color:red;">Device Settings button.</strong> It is located next to active subscriptions`
        },
        {
            title: 'The purchased subscription does not work',
            text: 'Do not connect the receiver in advance, wait a few minutes and only then configure or turn on the receiver. Contact technical support if you have any problems.'
        },
        {
            title: 'Is it possible to watch from one account on two receivers?',
            text: 'Yes, when buying a package, you can choose the right number of receivers and watch from one home account.'
        }

    ],
    accounts : {
        show : 'show accounts',
        accounts : 'accounts',
        edited : "Last updated",
        create_help : "- For clients, friends, relatives, you can create more accounts.",
        create : "Create account",
        create_another : "Create  account",
        select : "select account"
    },
    transaction : {
        crypto_message : `<b>The exchange rate will be taken into account at the time of the transaction</b>`,
        crypto_notify : "Please send us the transaction number and the amount in this field after payment",
        crypto_notify_message : "The number and amount of the transaction. Comments if necessary",
        status : {
            PAY : "Pay",
            REFUND : "Refund",
            MANUAL : "Manual Transaction",
        },
        no_wallets : "No payment methods available"
    },
    menu : {
        Home : "Home",
        Subscriptions : "Cardshare",
        Dealer : "Dealer",
        Settings : "Settings",
        Help : "Help",
        History : "History",
        Transactions : "Payments",
        UserInfo : "Information",
        UserPackets : "Packets",
        UserLog : "Log",
        UserTransactions : "Payments",
        BalancePurchase : "Balance replenishment",
        Logout : "Log out",
        Cardshare : "Cardshare",
        Iptv : "IPTV",
        SuccessPayment : '',
        ErrorPayment : '',
    },
    log : {
        filter_from : "From this date",
        filter_to : "To this date",
    },
    users : {
        blocked : "Locked",
        oldpassword : "Old password",
        passwordnew : "New password",
        changePassword : "Change password",
        search_login: "Search by login",
        auto_continue : "Auto-renewal for a month",
        enableTv : "Watch and purchase allowed",
        clientCount : "Client count",
        addTransaction : "Add Transaction",
        watchToken  : "Password for watching",
        watchUser   : "Login for watching",
        watchData : "Credentials for watching"
    },
    help : {
        price_enabled : "Enable / Disable the purchase of a package from a given group of buyers",
        search_packet_name : "Search by packet name",
        autoRenew : "Auto-renewal",
        deniedPayAndWatch :  "Purchase and watching disallow",
        choose_period : "Choose period",
        choose_date_period : "Choose a start and end date for your subscription",
        not_valid_pay_period : "Please enter the correct subscription period",
        click_to_change_balance : "Click to change balance",
        pay_success : "Packet purchased!",
        device_info : "Configuring Devices",
        read_all : "Read more",
        coming_soon : "We are actively working on the launch of the IPTV service"
    },
    price : {
        balance : "Balance",
        purchase : "Top up balance",
        category : "Price list category",
        daily : 'Price per day',
        daily_price : '{price} - per day',
        month_price : '{price} - per month',
        days_free : '{days} day free',
        payable : "Paid",
        frees : 'Free',
        total : "Total",
        PACKET_DAYS_PRICE : "Price for the period",
        TV_FILTER_PRICE : "Price per device",
        PERSONAL_DISCOUNT : "Personal discount",
        PERIOD_DISCOUNT : "Discount for the period",
        DEALER_DISCOUNT : "Dealer discount",
        amountPurchase : "Amount to deposit",
        instruction : "Payment instructions",
        payment_via_agent : "Intermediary",
        payment_process : "Go to payment",
    },
    base : {
        account : 'Account',
        label : "Label",
        enabled : "Enabled",
        disabled : "Disabled",
        login : "Login",
        pass : "Password",
        email : "Email",
        password : "Password",
        password_confirm : "Password confirm",
        search : 'Search',
        success : "Success",
        done : "Successfully completed!",
        servers : "Servers",
        status : "Status",
        discount : "Discount",
        add : "Add",
        logo : "Logo",
        free : "Free",
        save : "Save",
        desc : "Description",
        server : "Server",
        name : "Name",
        free_packet : "Free packet",
        filter : "Filter",
        amount : "Price",
        note : "Note",
        bydefault : "Default",
        dealer : "Dealer",
        created : "Created",
        user : "User",
        clients : "Clients",
        info : "Information",
        pay : "Buy",
        sure : "Are you sure?",
        cancel : "Cancel",
        confirm : "Confirm",
        log : "Log",
        transactions : "Payments",
        time : "Time",
        edit : "Edit",
        prev :"previous",
        later : "later",
        send : "Send",
        code : "Verification code",
        full : "Open full",
        download : 'Download',
        copy : 'Copy to clipboard',
        parent_code : 'Parent code',
        close : 'close',
        subs : 'Subscriptions',
        remove : "Delete",
    },
    auth : {
        newspass : "New password",
        signIn : "Sign in",
        logout : "Log out",
        register : "Registration",
        forget : "Forgot password?",
        recover : "Send password",
        recover_msg : "The link for recovery will be sent to the mail",
        recover_success : 'Success! Now you can sign in to your account',
        verify_msg : "Check your email and enter the code to confirm registration",
        expired : "Your token has expired, enter your username and password to continue",
    },
    errors : {
        error : "Error",
        def : "",
        auth : "Wrong login or password",
        empty : "Filed is empty",
        email : "Incorrect email",
        pass_confirm: "Passwords do not match",
        size :  "Length must be from  {min} to {max}",
        VERIFY_WAIT : "Enter the verification code",
        VERIFY_INVALID : "Wrong the verification code",
        PACKET_PAY_DENIED : "Purchase and watching disallow!",
        PAUSE_DENIED : "Can't use pause so often",
        FREE_PACKET_USER_DENIED : "User is not allowed to purchase free packets",
        NO_MONEY : "Not enough funds to buy",
        DATE_NOT_CORRECT : "Incorrect subscription period specified",
        PACKET_NOT_FREE : "This packet is not free",
        PACKET_ALREADY_USED : "This packet has already been purchased",
        number : "Enter the number",
        DAYS_PERMIT : "No refunds",
        DUPLICATE_PERIOD : "You have an active subscription during this period",
        REFUND_DAYS_PERMIT : "Refunds not available for current subscription",
        WRONG_PASSWORD : "Wrong password",
        captcha : "Fill in the captcha",
        CAPTCHA_ERROR : "Wrong captcha",
        EMAIL_NOT_FOUND : "Email not found",
        DUPLICATE_ERROR : "This data is already in use",
        password_strong : "Numbers and letters",
        length :  "At least  {length} characters",
        LOGIN_EXIST : "This login already exist",
        EMAIL_EXIST : "This email already exist",
        login_regexp : "Only latin letters and numbers",
        LIMIT_ERROR : 'Sorry, the operation is not available at the moment',
        maxPurchase : 'Max - 25 euro',
        minPurchase : "Min - {value} euro"
    },
    packet : {
        subs : 'subscriptions',
        connections : "connections",
        custom_period : "Specify dates",
        days_period : "Days period",
        until: "Until",
        active_small : "Active",
        paused : "Paused",
        deferred : "Deferred",
        deviceFilter : "Connections (ip address) by account",
        free : "Free packets",
        free_days : "Days free",
        groups : "Subscriptions",
        pricelist : "Pricelist",
        ports : "Ports",
        filters : "Filters",
        caid : "CAID",
        ident : "IDENT",
        packets : "Packets",
        from : "From",
        to : "To",
        users : "Subscriptions",
        do_pause : "Pause subscription",
        do_unpause : "Unpause",
        refund : "Refunds",
        empty : "No packets to view",
        get_free_days : "Get free",
        pay :  "Buy packet [TEST]",
        pause_time : "Paused",
        deffered : "Deferred",
        archive : "Archive",
        active : "Active subscriptions",
        soon_expired : "will expire soon",
        packet_actions_help : `
        The subscription can be suspended at any time indefinitely. 
        In order to use the service again, 7 days must pass.
        \n \n \r \r
        For the remaining days on the subscription, you can return the money 
        to the balance
        `,
        autoContinueEnabled : `Auto-renewal is enabled, the subscription is renewed {days} days before the end of the current subscription`,
        no_subscriptions : "You don't have any active subscriptions, try a test subscription",
        no_subscriptions_warn : "You don't have any active subscriptions at the moment. Select the subscriptions that you would like to activate",
    },
    period : {
        day : "Day",
        days : "Days",
        week : "Week",
        month : "Month",
        months : 'Months',
        year : "Year",
        hour : 'Hours'
    },
    content : {
        main : {
            cabinet : "Enjoy!",
            title : "TV",
            pluses1 : "Free",
            pluses2 : "Watch on multiple devices",
            pluses3 : "Loyal affiliate program",
        }
    },
    iptv : {
        show_link : "Show playlist link",
        copy_link : "Copy playlist link to clipboard",
        download_file : "Download playlist file",
        channels : "channels",
        editor: "Channels editor"
    }
}
