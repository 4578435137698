<script>
import PlaylistEditor from "@/components/iptv/editor/PlaylistEditor.vue";

export default {
  name: "PlaylistEditorDIalog",
  components: {PlaylistEditor},
  props: {
    accountId: {
      type: String
    },
  },
  data() {
    return {
      editorDialog: false,
      loading: false
    }
  },
  methods: {
    open() {
      this.editorDialog = true
    },

    save() {
      this.$refs.editor.save()
    }
  },
}
</script>

<template>
  <v-dialog scrollable persistent fullscreen v-model="editorDialog">
    <v-card class="pt-2">
      <v-card-title>
        {{$t('iptv.editor')}}
        <v-spacer />
        <div>
          <v-btn :small="$vuetify.breakpoint.xs" @click="save" color="success">{{$t('base.save')}} <v-icon right>mdi-content-save</v-icon></v-btn>
          <v-btn :small="$vuetify.breakpoint.xs" icon @click="editorDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <playlist-editor @done="loading = false" ref="editor" :account-id="accountId" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>