import packetService from "@/service/api/packetService";

export default {
    data() {
        return {
            activeSubscriptions: [],
            archiveSubscriptions: [],
        }
    },
    computed: {
        hasSubs: function (){
            return this.activeSubscriptions.length
        }
    },
    created: function () {

    },
    methods: {
        getArchivePackets(accountId){
            packetService
                .getArchivePackets(accountId)
                .then(res => this.archiveSubscriptions = res.data)
                .finally(() => {})
        },
    },
}