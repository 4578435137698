import { render, staticRenderFns } from "./IptvSubscriptionsWidget.vue?vue&type=template&id=f35b6d84&scoped=true&"
import script from "./IptvSubscriptionsWidget.vue?vue&type=script&lang=js&"
export * from "./IptvSubscriptionsWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f35b6d84",
  null
  
)

export default component.exports