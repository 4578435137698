import { render, staticRenderFns } from "./SubscriptionsSimpleTable.vue?vue&type=template&id=f6ce8774&scoped=true&"
import script from "./SubscriptionsSimpleTable.vue?vue&type=script&lang=js&"
export * from "./SubscriptionsSimpleTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6ce8774",
  null
  
)

export default component.exports