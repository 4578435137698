<script>
import VueRecaptcha from "vue-recaptcha";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

import authService from "@/service/api/authService";

export default {
  name: "captcha",
  components: {VueRecaptcha, VueHcaptcha},
  created() {
    this.getSettings()
  },
  props: {},
  data() {
    return {
      type: "",
      token : "",
      result: ""
    }
  },
  computed: {},
  methods: {
    getSettings() {
      authService.captchaSettings().then(res => {
        this.type = res.data.type
        this.token = res.data.siteToken
      })
    },
    onVerify(result) {
      this.result = result
      this.$emit("verify", result)
    },
    onExpired() {
      this.$emit("expired")
    },
    reset() {
      this.result = ""

      if (this.type === "recaptcha") {
        this.$refs.google_recaptcha.reset()
      }
      if (this.type === "hcaptcha") {
        this.$refs.hcaptcha.reset()
      }
    },
    valid() {
      if (!this.type || this.type === "fake") {
        return true
      }

      return !!this.result
    }
  },
}
</script>

<template>
  <div>
    <vue-recaptcha
        v-if="type === 'recaptcha'"
        :sitekey="token"
        theme="dark"
        ref="google_recaptcha"
        @verify="onVerify"
        @expired="onExpired"
    />
    <vue-hcaptcha
        v-if="type === 'hcaptcha'"
        ref="hcaptcha"
        :sitekey="token"
        @verify="onVerify"
        @expired="onExpired"
        theme="dark" />
  </div>
</template>

<style scoped>

</style>